import runtimePolyfill from '@ux/runtime-polyfill';

export let privacyManager = null;

runtimePolyfill(
  [ 'Promise', 'Map', 'Set', 'Object.assign', 'String.prototype.repeat', 'Intl.~locale.en' ],
  () => import('./main').then((main) => {
    privacyManager = main.privacyManager;
  }),
);
